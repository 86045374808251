import { isPlatformBrowser } from '@angular/common';
import { Component, inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-base-modal',
  template: '',
})
export class BaseModal implements OnInit {
  platformId = inject(PLATFORM_ID);

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      history.pushState({ modalOpen: true }, 'Modal', '#modal');
    }
  }
}
