import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MemberService } from '@api-client';
import {
  ButtonComponent,
  CustomValidators,
  InputEmailComponent,
  InputTelComponent,
  InputTextComponent,
  ToastService,
} from '@common/angular';
import { IonContent, IonModal } from '@ionic/angular/standalone';
import { ModalHeaderComponent } from '../../components/modal-header/modal-header.component';
import { AuthStore } from '../../stores/auth.store';
import { BaseModal } from '../base.modal';

@Component({
  selector: 'app-additional-info',
  standalone: true,
  imports: [
    IonContent,
    CommonModule,
    InputTextComponent,
    InputEmailComponent,
    InputTelComponent,
    ModalHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
  ],
  templateUrl: './additional-info.modal.html',
  styleUrl: './additional-info.modal.scss',
})
export class AdditionalInfoModal extends BaseModal implements OnInit {
  modal!: IonModal;
  name?: string;
  email?: string;
  tel?: string;
  service = inject(MemberService);
  toast = inject(ToastService);
  authStore = inject(AuthStore);

  form = new FormGroup({
    name: new FormControl('', { validators: [Validators.required], nonNullable: true }),
    email: new FormControl('', {
      validators: [Validators.required, Validators.email],
      asyncValidators: [CustomValidators.memberEmail(this.service)],
      nonNullable: true,
    }),
    tel: new FormControl('', {
      validators: [Validators.required],
      asyncValidators: [CustomValidators.memberTel(this.service)],
      nonNullable: true,
    }),
  });

  override ngOnInit() {
    super.ngOnInit();
    this.form.patchValue({
      name: this.name,
      email: this.email,
      tel: this.tel,
    });
  }

  submit() {
    if (this.form.invalid) return;

    this.service
      .memberControllerCompleteOAuth({
        body: this.form.getRawValue(),
      })
      .subscribe(() => {
        this.toast.success('회원가입이 완료되었습니다.');
        this.authStore.fetchMember();
        this.modal.dismiss();
      });
  }
}
