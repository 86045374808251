<ion-content class="ion-padding">
  <main class="flex flex-col h-full gap-4">
    <h1>추가 정보 등록</h1>
    <p>회원가입을 완료하기 위해 추가 정보를 등록 해 주세요.</p>
    <form [formGroup]="form" (ngSubmit)="submit()" class="flex flex-col justify-between flex-1">
      <div class="flex flex-col flex-1 gap-4">
        <app-input-text label="이름" formControlName="name" placeholder="이름을 입력해주세요." />
        <app-input-email label="이메일" formControlName="email" placeholder="이메일을 입력해주세요." />
        <app-input-tel validate label="전화번호" formControlName="tel" placeholder="전화번호를 입력해주세요." />
      </div>
      <app-button type="submit" [disabled]="form.invalid">저장</app-button>
    </form>
  </main>
</ion-content>
