import { booleanAttribute, Component, inject, input } from '@angular/core';
import { IconComponent } from '@common/angular';
import { ModalController, Platform } from '@ionic/angular/standalone';
@Component({
  selector: 'app-modal-header',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './modal-header.component.html',
  styleUrl: './modal-header.component.scss',
})
export class ModalHeaderComponent {
  modalController = inject(ModalController);
  platform = inject(Platform);

  buttons = input<boolean, string | boolean>(false, { transform: booleanAttribute });

  async dismiss() {
    const top = await this.modalController.getTop();
    if (top) {
      await top.dismiss();
    }
  }
}
