<header class="flex items-center justify-between px-4 py-5">
  <app-icon name="material-symbols:arrow-back-ios" class="cursor-pointer text-neutral-500 size-6" (click)="dismiss()" />

  @if (buttons()) {

  <div class="flex items-center gap-2">
    <app-icon name="ic:round-search" class="text-neutral-500 size-6" />
    <div class="flex items-center">
      <app-icon name="solar:cart-check-bold-duotone" class="text-neutral-500 size-6" />
      <div class="flex items-center justify-center rounded-full size-5 bg-primary">
        <span class="font-bold text-white">0</span>
      </div>
    </div>
  </div>
  }
</header>
